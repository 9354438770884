/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';
html, body { height: 100%; }
body { margin: 0; font-family: Rubik, "Helvetica Neue", sans-serif; }
.switch{
    width: 88px !important;
    height: 35px !important;
    background: #2699FB 0% 0% no-repeat padding-box !important;
    border-radius: 6px !important;
    padding-top: 4px !important;
    color: #fff;
  }
  .switch small{
        top: 4px !important;
        left: 4px !important;
        width: 30px !important;
        height: 30px !important;
        border-radius: 5px !important;
  }
  .switch.checked small {
    right: 4px !important;
    left: auto !important;
}
.switch:focus{
    border: none;
    outline: none;
}

.navbar-bottom .carousel-indicators{
  display: none !important;
}
.navbar-bottom .carousel-control-next-icon {
  background-image: url('./assets/icons/right-chevron-50.png') !important;
}
.navbar-bottom .carousel-control-prev-icon{
  background-image: url('./assets/icons/left-chevron-50.png') !important;
}
.navbar-bottom .carousel-control-next {
  right: -100px !important;
}
.navbar-bottom .carousel-control-prev {
  left: -135px !important;
}
.navbar-bottom .carousel-item{
  width: auto !important;
}
@media only screen and (max-width: 1280px) {
  .navbar-bottom .carousel-control-next {
    right: -75px !important;
  }
  .navbar-bottom .carousel-control-prev {
    left: -90px !important;
  }
}
@media only screen and (max-width: 768px) {
  .navbar-bottom .carousel-control-next {
    right: -15px !important;
  }
  .navbar-bottom .carousel-control-prev {
    left: -40px !important;
  }
}

.body-modal-open {
  overflow: hidden;
}
